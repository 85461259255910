
import VueGridLayout from "vue-grid-layout"

export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    initialGrid: {
      type: Array,
      default: null,
    },
    isGridCameraUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cameraGridItems: [],
    }
  },
  watch: {
    cameraGridItems: {
      handler(value) {
        this.$emit("grid-configuration-updated", value)
      },
      deep: true,
    },
    initialGrid: {
      handler: "initiateGrid",
      deep: true,
    },
  },
  mounted() {
    this.initiateGrid()
  },
  methods: {
    initiateGrid() {
      if (this.initialGrid && !this.isGridCameraUpdate) {
        this.cameraGridItems = this.initialGrid.reduce((acc, item) => {
          const camera = this.items.find((el) => el.exid === item.camera)

          if (camera) {
            acc.push({
              ...item,
              camera: { ...camera },
            })
          }

          return acc
        }, [])
      } else {
        const tileSize = this.items.length > 16 ? 1 : 2
        const step = this.items.length > 16 ? 8 : 4

        this.cameraGridItems = this.items.map((item, index) => {
          return {
            x: (index % step) * tileSize, // 0,2,4,6,0,2,4,6...
            y: Math.floor(index / step) * tileSize, /// 0,0,0,0,2,2,2,2...
            w: tileSize,
            h: tileSize,
            i: index.toString(),
            camera: { ...item },
          }
        })
      }
    },
  },
}
