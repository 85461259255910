
// NEW WIDGET SYSTEM
import WidgetPreview from "@/components/widgets/WidgetPreview"
import { useWidgetStore } from "@/stores/widgets"

export default {
  components: {
    WidgetPreview,
  },
  layout: "widget",
  async asyncData({ error }) {
    try {
      const widgetStore = useWidgetStore()
      await widgetStore.fetchWidget()

      return { widget: widgetStore.currentWidget }
    } catch (e) {
      console.error(e)
      error({ message: e })

      return
    }
  },
}
