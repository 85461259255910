
import VideoWallItemLiveView from "@/components/videoWalls/VideoWallItemLiveView"
import VideoWallActionButtons from "@/components/videoWalls/VideoWallActionButtons"
import VideoWallConfigurationDialog from "@/components/videoWalls/VideoWallConfigurationDialog"
import VueGridLayout from "vue-grid-layout"
import {
  AnalyticsEvent,
  Camera,
  Media,
  VideoWallItem,
  VideoWallItemType,
  VideoWallPresetItem,
} from "@evercam/shared/types"
import { PropType } from "vue"
import { useVideoWallStore } from "@evercam/dashboard/stores/videoWall"
import { useAccountStore } from "@evercam/dashboard/stores/account"
import { mapStores } from "pinia"

export default {
  name: "VideoWallGallery",
  components: {
    VideoWallItemLiveView,
    VideoWallActionButtons,
    VideoWallConfigurationDialog,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  props: {
    items: {
      type: [] as PropType<VideoWallItem<Camera | Media>[]>,
      default: () => [],
    },
    preset: {
      type: Array as PropType<VideoWallPresetItem[]>,
      default: () => [],
      required: false,
    },
    global: {
      type: Boolean,
      default: false,
      required: false,
    },
    showSettings: {
      type: Boolean,
      default: true,
      required: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
      required: false,
    },
    refreshRate: {
      type: [Number, undefined],
      default: 50_000, // 1 snapshot every 50 seconds
    },
  },
  data() {
    return {
      videoWallType: VideoWallItemType,
      showVideoWallActionButton: true,
      isConfigurationDialogVisible: false,
      rowHeight: 1000,
      colWidth: 1000,
      gridSize: 8,
      rowCount: 8,
      containerStyle: {},
      fullscreenPreset: [],
      isFullScreen: false,
      generatedPreset: [],
    }
  },
  computed: {
    ...mapStores(useVideoWallStore, useAccountStore),
    projectHasPreset() {
      return this.preset?.length > 0
    },
    currentPreset() {
      if (this.isFullScreen) {
        return this.fullscreenPreset
      } else if (this.projectHasPreset) {
        // Note: for now, we will only show cameras in the grid since we don't have media/weather support

        return this.preset.filter((item) => item.camera)
      }

      return this.generatedPreset
    },
    gridKey() {
      return JSON.stringify(this.preset)
    },
  },
  watch: {
    isConfigurationDialogVisible(visible) {
      this.$analytics.saveEvent(AnalyticsEvent.VideoWallToggleConfigDialog, {
        visible,
      })
    },
    projectHasPreset: {
      handler(hasPreset) {
        if (!hasPreset) {
          return
        }
        this.gridSize = 8
        this.rowCount = 8
        this.$nextTick(this.setTileSize)
      },
      immediate: true,
    },
    isFullScreen: {
      handler() {
        this.$nextTick(this.setTileSize)
      },
    },
    "videoWallStore.isLoading": {
      handler(newVal) {
        if (!newVal) {
          this.initPreset()
        }
      },
    },
  },
  mounted() {
    this.$registerInactivityListener({
      callback: this.onIdle,
      duration: 3,
      reminders: [110],
    })
    this.initPreset()
  },
  beforeDestroy() {
    this.videoWallStore.preset = []
  },
  methods: {
    initPreset() {
      if (this.projectHasPreset || this.videoWallStore.isLoading) {
        return
      }

      this.generatedPreset = this.generatePreset()
      this.$nextTick(this.setTileSize)
    },
    onSnapshotLoaded() {
      this.$nextTick(this.setTileSize)
    },
    setTileSize() {
      const container = this.$refs.container as HTMLElement
      if (!container) {
        return
      }
      const rect = container.getBoundingClientRect()
      const availableHeight = window.innerHeight - rect.top
      const containerHeight = this.isPreview ? "100%" : `${availableHeight}px`
      // First set the container size
      this.containerStyle = {
        height: containerHeight,
        position: "relative",
        overflow: "hidden",
      }
      // Then calculate grid dimensions based on the new container size
      const updatedRect = container.getBoundingClientRect()
      this.rowHeight = Math.floor(updatedRect.height / this.rowCount)
      this.colWidth = Math.floor(updatedRect.width / this.gridSize)
    },
    onIdle() {
      this.showVideoWallActionButton = false
    },
    mouseMove() {
      this.showVideoWallActionButton = true
    },
    openSettings() {
      this.isConfigurationDialogVisible = true
    },
    closeSettings() {
      this.isConfigurationDialogVisible = false
    },
    toggleItem(i) {
      if (this.isFullScreen) {
        this.isFullScreen = false
        this.fullscreenPreset = []
      } else {
        this.fullscreenPreset = this.currentPreset.reduce((acc, item) => {
          if (item.i === i) {
            return [
              {
                ...item,
                x: 0,
                y: 0,
                w: this.gridSize,
                h: this.rowCount,
              },
            ]
          }

          return acc
        }, [])
        this.isFullScreen = true
      }

      this.$analytics.saveEvent(
        AnalyticsEvent.VideoWallToggleCameraFullscreen,
        { active: this.isFullScreen }
      )
    },
    generatePreset() {
      const numberOfItems = this.items.length
      // 1 - use sqrt function to calculate the grid size depending on the number of items
      this.gridSize = Math.ceil(Math.sqrt(numberOfItems))
      // 2 -recalculate the number of items to fill the grid depending on the grid size
      let totalGridCells = this.gridSize * this.gridSize
      // 3 - calculate the number of empty columns to remove
      let emptyColumnsLength = totalGridCells - numberOfItems
      // 4 - calculate the number of rows to remove
      const numberOfEmptyLinesToRemove = Math.floor(
        emptyColumnsLength / this.gridSize
      )
      // 5 - calculate the number of rows to keep
      this.rowCount =
        emptyColumnsLength - this.gridSize > 0
          ? this.gridSize - numberOfEmptyLinesToRemove
          : this.gridSize

      // 6 - generate the grid layout
      return this.generateFixedGrid(this.gridSize, this.rowCount)
    },
    generateFixedGrid(cols, rows) {
      const layout = []
      let index = 0
      for (let y = 0; y < rows; y++) {
        for (let x = 0; x < cols; x++) {
          if (index >= this.items.length) break
          layout.push({
            i: index.toString(),
            x,
            y,
            w: 1,
            h: 1,
            camera: this.items[index].value,
          })
          index++
        }
      }

      return layout
    },
  },
}
