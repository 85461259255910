
import Vue, { PropType } from "vue"
import { EZoomable } from "@evercam/ui"
import { AdminCamera, Camera, CameraExid } from "@evercam/shared/types/camera"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import axios from "@evercam/shared/api/client/axios"

export default Vue.extend({
  name: "JpegPlayer",
  components: {
    EZoomable,
  },
  props: {
    camera: {
      type: Object as PropType<Camera | AdminCamera>,
      required: true,
    },
    refreshRate: {
      type: Number,
      default: 50_000,
    },
    delay: {
      type: Number,
      default: 0,
    },
    isZoomable: {
      type: Boolean,
      default: true,
    },
    isPlaying: {
      type: Boolean,
      default: true,
    },
    token: {
      type: String,
      default: "",
    },
    resize: {
      type: Boolean,
      default: true,
    },
    initialTimestamp: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      src: null,
      bluredBgSrc: null,
      lastRenderedSrc: "",
      retryCount: 0,
      maxRetries: 3,
      loading: false,
    }
  },
  computed: {
    cameraExid(): CameraExid {
      return (this.camera as AdminCamera).exid || (this.camera as Camera).id
    },
    cameraUrl(): string {
      return `${axios.env.baseUrl}/cameras/${this.cameraExid}`
    },
  },
  watch: {
    isPlaying(v) {
      if (v) {
        this.refresh()
      }
    },
    resize(v) {
      this.getLatestSnapshotSrc(v)
    },
  },
  created() {
    this.loading = true
    this.$setTimeout(this.refresh, this.delay)
  },
  methods: {
    refresh() {
      this.retryCount = 0
      this.getLatestSnapshotSrc(this.resize)
    },
    getQueryParams(): string {
      return `authorization=${this.token}&view=true`
    },
    async getLatestSnapshotSrc(resize = true, forceLatest = false) {
      const currentTime = this.$moment.tz(this.camera.timezone).valueOf()

      let result: any = null
      try {
        if (
          !forceLatest &&
          this.initialTimestamp &&
          currentTime <= this.initialTimestamp + this.refreshRate
        ) {
          result = (
            await EvercamApi.recordings.nearest(
              this.camera.id,
              this.$moment(this.initialTimestamp)
                .tz(this.camera.timezone)
                .toISOString(),
              { token: this.token, includeImage: false }
            )
          ).snapshots?.[0]
        } else {
          result = await EvercamApi.recordings.latest(this.camera.id, {
            token: this.token,
            includeImage: false,
          })
          this.$emit(
            "update-initial-timestamp",
            this.$moment(result.createdAt)?.tz(this.camera.timezone).valueOf()
          )
        }
      } catch (_) {
        await this.onImageError(forceLatest)

        return
      }

      const snapshotSrc = `${
        this.cameraUrl
      }/recordings/snapshots/${this.$moment(result.createdAt)
        ?.tz(this.camera.timezone)
        .toISOString()}?${this.getQueryParams()}`

      this.src = resize
        ? this.$imgproxy.get720pResizedImageUrl(snapshotSrc)
        : snapshotSrc
    },
    getThumbnailSrc(): string {
      return `${this.cameraUrl}/thumbnail?${this.getQueryParams()}}`
    },
    onImageLoaded() {
      this.bluredBgSrc = this.src
      this.$emit("snapshot-loaded")
      this.loading = false
      if (this.isPlaying) {
        const currentTime = this.$moment.tz(this.camera.timezone).valueOf()
        if (
          !this.lastRenderedSrc &&
          this.initialTimestamp &&
          currentTime <= this.initialTimestamp + this.refreshRate
        ) {
          this.$setTimeout(
            this.refresh,
            this.initialTimestamp + this.refreshRate - currentTime
          )
        } else {
          this.$setTimeout(this.refresh, this.refreshRate)
        }
      }
      this.lastRenderedSrc = this.src
    },
    async onImageError(forceLatest) {
      if (forceLatest) {
        this.getLatestSnapshotSrc(this.resize, true)
      } else if (this.retryCount < this.maxRetries) {
        this.retryCount++
        await this.getLatestSnapshotSrc(false)
      } else if (this.lastRenderedSrc && this.src !== this.lastRenderedSrc) {
        this.src = this.lastRenderedSrc
      } else {
        this.src = this.getThumbnailSrc()
      }
    },
  },
})
